// @ts-strict-ignore
import _ from 'lodash';
import moment from 'moment-timezone';
import tinycolor from 'tinycolor2';
import { Force, Node } from 'labella';
import { addCursor, deleteCursor as deleteCursorAction, toggleCursorSelection } from '@/trend/toolbar/cursor.actions';
import { STRING_UOM } from '@/main/app.constants';
import { formatNumber } from '@/utilities/numberHelper.utilities';
import { getYValue } from '@/utilities/utilities';
import {
  AUTO_UPDATE,
  ITEM_TYPES,
  SAMPLE_OPTIONS,
  SHADED_AREA_CURSORS,
  TREND_VIEWS,
} from '@/trendData/trendData.constants';
import { formatDuration } from '@/datetime/dateTime.utilities';
import {
  Cursor,
  CursorPoint,
  DEFAULT_CURSOR_VALUES,
  LABEL_CAPSULE_LINE_HEIGHT,
  LABEL_X_OFFSET,
  POINT_MARKER_SIZE,
  PointerYValue,
  Z_INDEX,
  ZERO_CAPSULE_TOLERANCE,
} from '@/utilities/cursor.constants';
import { getItemRanges, getItemYAxis } from '@/utilities/chartHelper.utilities';
import { CursorData, DurationDataForCursors, TrendDataForCursors } from '@/annotation/interactiveContent.types';
import { chartLanes } from '@/utilities/chartLanes';
import { CURSOR_TOOLTIP_HEIGHT } from '@/trend/trendViewer/trendViewer.constants';

/**
 * This class facilitates drawing cursors on a Highcharts chart.
 *
 * In order to facilitate interactive content, this service should NOT have dependencies on stores, or any utilities
 * that need stores like findItemIn. TODO: CRAB-29707 - Write test or rule to enforce this
 */
export class CursorsService {
  #hoverCursor: Cursor = { ...DEFAULT_CURSOR_VALUES };
  #storeCursors = [];
  #nowCursor: Cursor & { nowLine: Highcharts.SVGElement } = {
    ...DEFAULT_CURSOR_VALUES,
    nowLine: undefined,
  };

  /**
   * Sync the sqCursorStore into the local cursors object, pruning out any cursors which have been deleted
   *
   * @param isCapsuleTime - If true, the capsuletime cursors will sync; otherwise, calendar time cursors
   *   will sync
   * @param cursorData - Required data from sqCursorStore
   * @param trendData - Required data from sqTrendStore
   * @param stitchBreaks - from sqTrendCapsuleStore
   */
  syncCursorsWithStore(
    isCapsuleTime: boolean,
    cursorData: CursorData,
    trendData: TrendDataForCursors,
    stitchBreaks: { breakSize: number; from: number; to: number }[],
  ) {
    const currentCursors = isCapsuleTime ? cursorData.capsuleCursors : cursorData.calendarCursors;
    const orphanedXValues = _.difference(_.map(this.#storeCursors, 'xValue'), _.map(currentCursors, 'xValue'));

    _.forEach(orphanedXValues, (orphanX) => {
      const orphan = _.find(this.#storeCursors, ['xValue', orphanX]);
      if (orphan) {
        deleteCursor(orphan);
        _.remove(this.#storeCursors, orphan);
      }
    });

    _.forEach(currentCursors, (cursor: any) => {
      let storeCursor = _.find(this.#storeCursors, ['xValue', cursor.xValue]);

      if (!storeCursor) {
        storeCursor = {
          xValue: cursor.xValue,
          points: {},
        };
      } else if (!_.isEqual(_.mapValues(cursor.points, 'length'), _.mapValues(storeCursor.points, 'length'))) {
        // y values have changed
        deleteCursor(storeCursor);
        _.remove(this.#storeCursors, storeCursor);
        storeCursor = {
          xValue: cursor.xValue,
          points: {},
        };
      }
      _.merge(storeCursor, cursor);
      this.#storeCursors.push(storeCursor);
    });

    // if we are in chain view we need to ensure we filter out all the cursors that are dropped outside a capsule
    this.#storeCursors = _.reject(
      this.#storeCursors,
      (cursor) =>
        trendData.view === TREND_VIEWS.CHAIN &&
        _.some(stitchBreaks, ({ from, to }) => _.inRange(cursor.xValue, from, to)),
    );
  }

  /**
   * Redraw all cursors using the current chart extents, recalculating the pixel values from the x/y-values in each
   * cursor.
   *
   * @param chart - Highcharts chart on which to draw cursors
   * @param capsuleLaneHeight - Number of Pixels taken up by Capsules
   */
  drawCursors(
    chart: Highcharts.Chart,
    capsuleLaneHeight: number,
    trendData: TrendDataForCursors,
    durationData: DurationDataForCursors,
    longestCapsuleSeriesDuration: number,
    cursorData: CursorData,
    timezoneName: string,
    items: { id: string; lane: number }[],
  ) {
    // Make sure that the chart has been created
    if (!chart) {
      return;
    }
    const capsuleTime = trendData.view === TREND_VIEWS.CAPSULE;
    this.drawNowCursor(chart, trendData, durationData, longestCapsuleSeriesDuration);

    // draw selected cursors after un-selected ones so they appear in the foreground
    const [selectedDisplayCursors, unselectedDisplayCursors] = _.partition(this.#storeCursors, 'selected');

    _.forEach(unselectedDisplayCursors, (cursor) =>
      this.drawCursor(
        chart,
        capsuleLaneHeight,
        cursor,
        capsuleTime,
        trendData,
        durationData,
        longestCapsuleSeriesDuration,
        cursorData,
        timezoneName,
        items,
      ),
    );
    _.forEach(selectedDisplayCursors, (cursor) =>
      this.drawCursor(
        chart,
        capsuleLaneHeight,
        cursor,
        capsuleTime,
        trendData,
        durationData,
        longestCapsuleSeriesDuration,
        cursorData,
        timezoneName,
        items,
      ),
    );
  }

  /**
   * Redraw the specified cursor using the current chart extents, recalculating the pixel values from the x/y-values
   * in the cursor. For series that are displayed as bars or series that are displayed "samples only" cursors are
   * only shown when there is actual data visible (aka, the mouse is over the bar or over the sample).
   *
   * @param chart - Highcharts chart on which to draw cursors
   * @param capsuleLaneHeight - Number of Pixels taken up by Capsules
   * @param cursor - Cursor to redraw
   * @param isCapsuleTime - flag indicating capsule time
   * @param itemsWithLaneInfo - list of items in display, with their lane number
   * @param [showXLabel] - flag to show or hide x value label (default true)
   * @param [crosshairColor] - what color the crosshair cursor line should be
   */
  drawCursor(
    chart: Highcharts.Chart,
    capsuleLaneHeight: number,
    cursor: Cursor,
    isCapsuleTime: boolean,
    trendData: TrendDataForCursors,
    durationData: DurationDataForCursors,
    longestCapsuleSeriesDuration: number,
    cursorData: CursorData,
    timezoneName: string,
    itemsWithLaneInfo: { id: string; lane: number }[],
    showXLabel = true,
    crosshairColor = '#C0D0E0',
    updateXPixel = true,
    xAxisOverride: Highcharts.Axis = undefined,
  ) {
    if (
      this.updateCursorPixels(
        chart,
        cursor,
        capsuleLaneHeight,
        trendData,
        durationData,
        longestCapsuleSeriesDuration,
        itemsWithLaneInfo,
        updateXPixel,
        xAxisOverride,
      )
    ) {
      if (showXLabel) {
        this.drawXLabel(chart, cursor, cursorData, timezoneName);
      }
      this.drawCrosshair(chart, cursor, capsuleLaneHeight, crosshairColor);
      _.forEach(cursor.points, (points, id) => {
        _.forEach(points, (point) => {
          if (_.isNumber(point.yValue) || !_.isEmpty(point.yValue)) {
            if (
              point.sampleDisplayOption === SAMPLE_OPTIONS.SAMPLES ||
              point.sampleDisplayOption === SAMPLE_OPTIONS.BAR
            ) {
              if (point.showIndicator) {
                this.drawCursorPointLabel(chart, cursor, point, cursorData);
                if (point.sampleDisplayOption === SAMPLE_OPTIONS.SAMPLES) {
                  this.drawCursorPointCircle(chart, cursor, point);
                }
              }
            } else {
              if (point.showIndicator) {
                this.drawCursorPointCircle(chart, cursor, point);
              }

              this.drawCursorPointLabel(chart, cursor, point, cursorData);
            }
          } else {
            deleteCursorPoint(point);
          }
        });
      });

      this.deconflictLabels(cursor, chart, capsuleLaneHeight, cursorData, showXLabel);
      // anchors are drawn last to ensure they are displayed on top of the value labels so they remain selectable.
      this.drawAnchor(chart, cursor, capsuleLaneHeight, isCapsuleTime);
    } else {
      deleteCursor(cursor);
    }
  }

  /**
   * Translates the x/y value locations to x/y pixel locations for the specified cursor
   *
   * @param chart - Highcharts chart on which to draw cursors
   * @param cursor - the cursor to update
   * @param capsuleLaneHeight - the number of pixels taken up by the capsule lane
   * @returns True if cursor is within the bounds of the current trend display and had its xPixel and yPixel
   *   values updated, otherwise false
   */
  updateCursorPixels(
    chart: Highcharts.Chart,
    cursor: Cursor,
    capsuleLaneHeight: number,
    trendData: TrendDataForCursors,
    durationData: DurationDataForCursors,
    longestCapsuleSeriesDuration: number,
    items: { id: string; lane: number }[],
    updateXPixel = true,
    xAxisOverride: Highcharts.Axis = undefined,
  ) {
    if (!chart) {
      return false;
    }

    const xAxis = xAxisOverride ?? chart.xAxis[0];

    // Skip updating anything that is off screen
    if (
      cursor.xValue < this.getStartValue(trendData, durationData) ||
      cursor.xValue > this.getEndValue(trendData, durationData, longestCapsuleSeriesDuration)
    ) {
      _.forEach(cursor.points, (points) => {
        _.forEach(points, (point) => {
          point.yPixel = undefined;
        });
      });

      return false;
    }

    if (updateXPixel) {
      cursor.xPixel = xAxis.translate(cursor.xValue) + chart.plotBox.x;
    }

    _.forEach(cursor.points, (points, id) => {
      _.forEach(points, (point) => {
        let yValue: number | string = point.yValue;

        if (point.isStringSeries) {
          // use the stringEnum to figure out the yValue to use with the axis
          yValue = _.chain(point.stringEnum).find(['stringValue', yValue]).get('key', '0').value();
        }

        const yAxis = getItemYAxis(chart, {
          id: point.itemId,
          capsuleSetId: point.capsuleSetId,
          itemType: point.itemType,
        });

        if (!yAxis) {
          // Skip points without an axis
          point.yPixel = undefined;
        } else if (point.itemType === ITEM_TYPES.CAPSULE) {
          // Capsules don't need to worry about being outside their lane, so can use the built-in positioner
          point.yPixel = yAxis.toPixels(yValue);
        } else {
          const { offset, height } = chartLanes.computeLaneValues(point.lane, {
            items,
            chart,
            capsuleLaneHeight,
            isCapsuleTime: trendData.view === TREND_VIEWS.CAPSULE,
          });
          let yPixelRelative = yAxis.toPixels(yValue);
          // For points outside of the lane show them at the edge of the lane
          if (yPixelRelative < offset) {
            point.showIndicator = false;
            yPixelRelative = offset;
          } else if (yPixelRelative > offset + height) {
            point.showIndicator = false;
            yPixelRelative = offset + height;
          }

          point.yPixel = yPixelRelative;
        }
      });
    });

    return true;
  }

  /**
   * Draw a vertical crosshair at the specified cursor location
   *
   * @param chart - Highcharts chart on which to draw cursors
   * @param cursor - Information about the cursor to draw
   * @param capsuleLaneHeight - Number of Pixels taken up by Capsules
   * @param [crosshairColor] - what color the crosshair cursor line should be
   */
  drawCrosshair(chart: Highcharts.Chart, cursor: Cursor, capsuleLaneHeight: number, crosshairColor = '#C0D0E0') {
    destroySVGElement(cursor.crosshair);
    cursor.crosshair = chart.renderer
      .rect(cursor.xPixel, chart.plotBox.y, 1, chart.plotBox.height, 0)
      .attr({
        'zIndex': 1,
        'class': `${cursorSVGPrefix(cursor)}-crosshair`,
        'stroke': crosshairColor,
        'stroke-width': 0.5,
        'fill': crosshairColor,
        'pointer-events': 'none',
      })
      .add();
  }

  /**
   * Draw the anchor icon at the top of a named cursor. If the cursor does not have a name, nothing is drawn.
   *
   * @param chart - Highcharts chart on which to draw
   * @param cursor - Information about the cursor to draw
   * @param capsuleLaneHeight - Number of Pixels taken up by Capsules
   * @param isCapsuleTime - whether the trend is in capsule view mode
   */
  drawAnchor(chart: Highcharts.Chart, cursor: Cursor, capsuleLaneHeight: number, isCapsuleTime: boolean) {
    destroySVGElement(cursor.anchor);
    destroySVGElement(cursor.deleteIcon);

    if (cursor.name) {
      // vertically offset the cursor label to ensure it is not covered by the lane labels and can be clicked
      cursor.anchor = chart.renderer
        .label(
          cursor.name,
          cursor.xPixel,
          chart.plotBox.y + 28,
          'rect',
          null,
          null,
          false,
          false,
          `${cursorSVGPrefix(cursor)}-anchor`,
        )
        .attr({
          fill: '#e5e4e2',
          zIndex: Z_INDEX,
        })
        .css({ fontSize: '11px' })
        .on('click', (e) => toggleCursorSelection(cursor, false, e.ctrlKey || e.metaKey))
        .add();

      if (cursor.selected) {
        const normalState = {
          'zIndex': Z_INDEX + 10,
          'fill': null,
          'stroke-width': 0,
          'style': { 'color': '#999999', 'font-size': '10px' },
        };

        const hoverState = {
          ...normalState,
          style: {
            'color': '#D9534F',
            'font-size': '10px',
            'font-weight': 'bold',
          },
        };

        cursor.deleteIcon = chart.renderer
          .button(
            '✖',
            cursor.xPixel + _.get(cursor.anchor, 'width', 0) - 12,
            chart.plotBox.y + 14,
            () => deleteCursorAction(cursor.xValue, isCapsuleTime),
            normalState,
            hoverState,
            hoverState,
            hoverState,
          )
          .add();
      }
    }
  }

  /**
   * Draw the x-value label at the bottom of a named cursor. If the cursor does not have a value, nothing is drawn.
   *
   * @param chart - Highcharts chart on which to draw
   * @param cursor - Information about the cursor to draw
   */
  drawXLabel(chart: Highcharts.Chart, cursor: Cursor, cursorData: CursorData, timezoneName: string) {
    const offset = 9;

    destroySVGElement(cursor.xLabel);

    if (cursorData.showValues) {
      cursor.xLabel = chart.renderer
        .label(
          formatXLabel(cursor.xValue, timezoneName),
          cursor.xPixel,
          chart.plotBox.height + chart.plotBox.y - offset,
          'rect',
          null,
          null,
          false,
          false,
          `${cursorSVGPrefix(cursor)}-x-label`,
        )
        .attr({
          fill: '#EAF3F4',
          zIndex: Z_INDEX + 1,
          r: 2,
          padding: 1,
        })
        .add();

      // Center it under the cursor if there is enough room
      const midpointX = cursor.xPixel - cursor.xLabel.width / 2;
      const chartRightX = chart.plotBox.x + chart.plotBox.width;
      if (midpointX < chartRightX && midpointX > chart.plotBox.x) {
        if (midpointX + cursor.xLabel.width < chartRightX) {
          cursor.xLabel.xSetter(midpointX);
        } else {
          cursor.xLabel.xSetter(cursor.xPixel - cursor.xLabel.width);
        }
      }
    }
  }

  /**
   * Redraw the point circle for a specific point in a cursor.
   *
   * @param chart - Highcharts chart on which to draw cursors
   * @param cursor - Cursor in which the point exists
   * @param cursor.xPixel - x-pixel location of this cursor
   * @param point - Point to redraw
   * @param point.yPixel - y-pixel location of this cursor
   * @param item - Series item for this point
   * @param item.color - Color for this point
   */
  drawCursorPointCircle(chart: Highcharts.Chart, cursor: Cursor, point: CursorPoint) {
    destroySVGElement(point.circle);

    if (_.isFinite(point.yPixel)) {
      point.circle = chart.renderer
        .circle(cursor.xPixel, point.yPixel, POINT_MARKER_SIZE)
        .attr({
          'class': `highcharts-${cursorSVGPrefix(cursor)}-point`,
          'fill': point.color,
          'pointer-events': 'none',
          'stroke': 'white',
          'stroke-width': 1,
          'zIndex': Z_INDEX,
        })
        .add();
    }
  }

  /**
   * Redraw the point label for a specific point in a cursor.
   *
   * @param chart - Highcharts chart on which to draw cursors
   * @param cursor - Cursor in which the point exists
   * @param point - Point to redraw
   * @param {Object} item - Series item for this point
   * @param {String} item.color - Color for this point
   */
  drawCursorPointLabel(chart: Highcharts.Chart, cursor: Cursor, point: CursorPoint, cursorData: CursorData) {
    destroySVGElement(point.label);

    if (cursorData.showValues && _.isFinite(point.yPixel)) {
      if (!_.isUndefined(point.labelText)) {
        if (point.labelText) {
          const labelText = `<span style="border-color: ${point.color}">${point.labelText}</span>`;
          const lineCount = (point.labelText.match(/<br>/g) || []).length + 1;
          const yPixel = point.yPixel - lineCount * LABEL_CAPSULE_LINE_HEIGHT;
          point.label = chart.renderer
            .label(
              labelText,
              cursor.xPixel + LABEL_X_OFFSET,
              yPixel,
              'rect',
              cursor.xPixel,
              point.yPixel,
              true,
              false,
              'cursor-capsule-label',
            )
            .attr({
              padding: 0,
            })
            .css({
              fontSize: '10px',
              fontFamily: 'inherit',
            })
            .add();
        }
      } else {
        let labelText = _.isFinite(point.yValue)
          ? formatNumber(point.yValue, point.formatOptions)
          : point.yValue.toString();
        if (labelText && (point.valueUnitOfMeasure || point.sourceValueUnitOfMeasure)) {
          const unitOfMeasure = point.valueUnitOfMeasure
            ? point.valueUnitOfMeasure
            : `<span style="font-style: italic;">${point.sourceValueUnitOfMeasure}</span>`;
          labelText = `${labelText} ${unitOfMeasure}`;
        }

        point.label = chart.renderer
          .label(
            labelText,
            cursor.xPixel + LABEL_X_OFFSET,
            point.yPixel,
            'rect',
            cursor.xPixel,
            point.yPixel,
            false,
            true,
            `${cursorSVGPrefix(cursor)}-y-label`,
          )
          .attr({
            fill: point.color,
            zIndex: Z_INDEX,
            padding: 2,
            r: 2,
          })
          .css({
            color: tinycolor(point.color).isDark() ? '#fff' : '#000',
          })
          .add();
      }
    }
  }

  /**
   * Adjust the location of a set of labels for a cursor to keep them from overlapping. The algorithm attempts to
   * put them as close to the ideal Y position as possible without overlapping the X-value label. If there is no
   * room for a label it is hidden.
   *
   * @param cursor - Cursor with the points that have SVG labels
   * @param chart - Highcharts chart on which to draw cursors
   * @param capsuleLaneHeight - Number of Pixels taken up by Capsules
   * @param showXLabel - flag to show or hide x value label (default true)
   */
  deconflictLabels(
    cursor: Cursor,
    chart: Highcharts.Chart,
    capsuleLaneHeight: number,
    cursorData: CursorData,
    showXLabel = true,
  ) {
    const minPos = 0;
    const maxPos = cursor.xLabel?.y ?? chart.plotBox.height;

    if (cursorData.showValues) {
      const labels = _.chain(cursor.points).values().flatten().filter('label').map('label').sortBy('y').value();
      const isBeyondRight = _.some(labels, (label) => label.x + label.width > chart.plotBox.x + chart.plotBox.width);
      const nodes: Node[] = _.chain(labels)
        .filter('padding') // Filter to remove capsule labels which don't need deconflicting
        .map((label) => new Node(label.y, label.height, label))
        .value();
      let forceInput;
      if (showXLabel) {
        forceInput = new Force({
          algorithm: 'none',
          minPos,
          maxPos,
          nodeSpacing: 1,
        });
      } else {
        forceInput = new Force();
      }
      const force = forceInput.nodes(nodes).compute();
      _.forEach(force.nodes(), (node: any) => {
        if (isBeyondRight) {
          node.data.xSetter(node.data.x - node.data.width - LABEL_X_OFFSET);
        }

        const halfLabelSize = node.data.height / 2;
        // currentPos is where dot will be and half the label will
        // be above or below it
        const allowedOverlapWithXCursor = 2;
        if (
          node.currentPos + halfLabelSize - allowedOverlapWithXCursor > maxPos ||
          node.currentPos + halfLabelSize < minPos
        ) {
          // Remove those that would bleed onto the capsules lane or out the bottom
          node.data.css({ display: 'none' });
        } else if (node.currentPos !== node.idealPos) {
          node.data.ySetter(node.currentPos);
        }
      });
    }
  }

  /**
   * Draws the vertical crosshair on the chart at the x-location of the pointer
   *
   * @param options.chart - Highcharts chart on which to draw cursors
   * @param options.xPixel - x-pixel coordinate location, in the reference frame of the plot area
   * @param options.xValue - value of the cursor along the x-axis
   * @param options.yValues - array of objects for all series on the chart, with the id as the key
   * @param options.yValues[].id - id of the series
   * @param options.yValues[].pointValue - interpolated y-value of this series at the specified x-pixel location
   * @param options.yValues[].valueUnitOfMeasure - Unit of measure for the y-value
   * @param options.capsuleLaneHeight - Number of Pixels taken up by Capsules
   * @param options.[showXLabel] - flag to show or hide x value label (default true)
   * @param options.[crosshairColor] - what color the crosshair cursor line should be
   */
  drawHoverCursor(options: {
    chart: Highcharts.Chart;
    xPixel: number;
    xValue: number;
    yValues: PointerYValue[];
    capsuleLaneHeight: number;
    trendData: TrendDataForCursors;
    durationData: DurationDataForCursors;
    longestCapsuleSeriesDuration: number;
    cursorData: CursorData;
    timezoneName: string;
    itemsWithLaneInfo: { id: string; lane: number }[];
    showXLabel?: boolean;
    crosshairColor?: string;
    xAxisOverride?: Highcharts.Axis;
    updateXPixel?: boolean;
  }) {
    const {
      chart,
      xPixel,
      xValue,
      yValues,
      capsuleLaneHeight,
      longestCapsuleSeriesDuration,
      itemsWithLaneInfo,
      showXLabel = true,
      crosshairColor = '#C0D0E0',
      xAxisOverride,
      trendData,
      durationData,
      cursorData,
      timezoneName,
      updateXPixel = true,
    } = options;
    this.clearHoverCursor();
    const capsuleTime = trendData.view === TREND_VIEWS.CAPSULE;
    this.#hoverCursor.xValue = xValue;
    this.#hoverCursor.xPixel = xPixel;
    this.#hoverCursor.points = _.chain(yValues)
      .groupBy('id')
      .mapValues((vals) =>
        _.map(
          vals,
          (val) =>
            ({
              yValue: val.pointValue,
              valueUnitOfMeasure: val.valueUnitOfMeasure,
              sourceValueUnitOfMeasure: val.sourceValueUnitOfMeasure,
              labelText: val.labelText,
              showIndicator: val.showIndicator,
              sampleDisplayOption: val.sampleDisplayOption,
              color: val.color,
              formatOptions: val.formatOptions,
              isStringSeries: val.isStringSeries,
              stringEnum: val.stringEnum,
              itemType: val.itemType,
              lane: val.lane,
              itemId: val.id,
              capsuleSetId: val.capsuleSetId,
              yPixel: undefined,
              circle: undefined,
              label: undefined,
            } as CursorPoint),
        ),
      )
      .value();

    this.drawCursor(
      chart,
      capsuleLaneHeight,
      this.#hoverCursor,
      capsuleTime,
      trendData,
      durationData,
      longestCapsuleSeriesDuration,
      cursorData,
      timezoneName,
      itemsWithLaneInfo,
      showXLabel,
      crosshairColor,
      updateXPixel,
      xAxisOverride,
    );
  }

  /**
   * Draws the now cursor (vertical dotted line at current time)
   *
   * @param chart - Highcharts chart on which to draw cursors
   */
  drawNowCursor(
    chart: Highcharts.Chart,
    trendData: TrendDataForCursors,
    durationData: DurationDataForCursors,
    longestCapsuleSeriesDuration: number,
  ) {
    let path;
    const xAxis = chart.xAxis[0];
    destroySVGElement(this.#nowCursor.nowLine);
    this.#nowCursor = { ...DEFAULT_CURSOR_VALUES, nowLine: undefined };

    if (durationData.autoUpdate.mode !== AUTO_UPDATE.MODES.OFF) {
      this.#nowCursor.xValue = durationData.autoUpdate.now;
      // ensure that we not draw a now cursor if it should be hidden by right aligned axis.
      if (
        this.#nowCursor.xValue < this.getStartValue(trendData, durationData) ||
        this.#nowCursor.xValue > this.getEndValue(trendData, durationData, longestCapsuleSeriesDuration)
      ) {
        return;
      }
      // We subtract one pixel to ensure the now line is visible on the trend when now mode is turned on and the
      // now line is located at the extreme right side of the trend.
      this.#nowCursor.xPixel = xAxis.translate(this.#nowCursor.xValue) + chart.plotBox.x - 1;
      path = ['M', this.#nowCursor.xPixel, chart.plotBox.y, 'L', this.#nowCursor.xPixel, chart.plotBox.height];
      this.#nowCursor.nowLine = chart.renderer
        .path(path)
        .attr({
          'zIndex': 1,
          'stroke': 'black',
          'stroke-width': '1.0',
          'stroke-dasharray': '1, 2',
          'pointer-events': 'none',
          'shape-rendering': 'crispEdges',
        })
        .add();
    }
  }

  /**
   * Creates a new cursor using the current hoverCursor properties
   */
  createCursor(isCapsuleTime: boolean, trendData: TrendDataForCursors, durationData: DurationDataForCursors) {
    const points = {};
    if (this.#hoverCursor.xValue >= this.getStartValue(trendData, durationData)) {
      _.forEach(this.#hoverCursor.points, (vals, id) => {
        if (vals) {
          points[id] = _.map(vals, (val) => {
            // Never display 'string's value unit of Measure - CRAB-15373
            return _.pickBy(
              val,
              (value, key) =>
                _.includes(
                  [
                    'yValue',
                    'labelText',
                    'color',
                    'formatOptions',
                    'sampleDisplayOption',
                    'isStringSeries',
                    'stringEnum',
                    'itemType',
                    'lane',
                    'capsuleSetId',
                    'itemId',
                  ],
                  key,
                ) ||
                (_.includes(['sourceValueUnitOfMeasure', 'valueUnitOfMeasure'], key) && value !== STRING_UOM),
            );
          });
        }
      });

      addCursor(this.#hoverCursor.xValue, points, isCapsuleTime);
    }
  }

  /**
   * Gets the start value based on the current trend view mode. In capsule time, it will be the lower
   * capsule time offset. In other trend view modes, it will be the display range start value.
   */
  getStartValue(trendData: TrendDataForCursors, durationData: DurationDataForCursors): number {
    return trendData.view === TREND_VIEWS.CAPSULE
      ? 0 + trendData.capsuleTimeOffsets.lower
      : durationData.displayRange.start.valueOf();
  }

  /**
   * Gets the end value based on the current trend view mode. In capsule time, it will be the upper
   * capsule time offset. In other trend view modes, it will be the display range end value.
   */
  getEndValue(
    trendData: TrendDataForCursors,
    durationData: DurationDataForCursors,
    longestCapsuleSeriesDuration: number,
  ): number {
    return trendData.view === TREND_VIEWS.CAPSULE
      ? longestCapsuleSeriesDuration + trendData.capsuleTimeOffsets.upper
      : durationData.displayRange.end.valueOf();
  }

  /**
   * Updates points in all existing cursors with the current set of series
   *
   * @param chart - Highcharts chart on which to draw cursors
   * @param {Object[]} items - List of items currently in the chart
   * @param isCapsuleTime - If true, capsule time cursors will be updated; otherwise, calendar time cursors
   *   will be updated
   */
  updateCursorItems(
    chart: Highcharts.Chart,
    items,
    isCapsuleTime: boolean,
    cursorData: CursorData,
    trendData: TrendDataForCursors,
    durationData: DurationDataForCursors,
    longestCapsuleSeriesDuration: number,
  ) {
    const cursors = isCapsuleTime ? cursorData.capsuleCursors : cursorData.calendarCursors;
    _.chain(cursors)
      .filter(
        (cursor: any) =>
          cursor.xValue >= this.getStartValue(trendData, durationData) &&
          cursor.xValue <= this.getEndValue(trendData, durationData, longestCapsuleSeriesDuration),
      )
      .forEach((cursor) => {
        const yValues = this.calculatePointerValues(chart, cursor.xValue, items, trendData);
        const points: Record<string, CursorPoint[]> = _.chain(
          trendData.view === TREND_VIEWS.CAPSULE
            ? this.reduceYValues(
                yValues,
                items,
                chartLanes.getChartSeriesDisplayHeight({ chart, capsuleLaneHeight: 0 }),
              )
            : yValues,
        )
          .groupBy('id')
          .mapValues((vals) =>
            _.map(
              vals,
              (val) =>
                ({
                  ..._.omit(val, ['sourceValueUnitOfMeasure', 'valueUnitOfMeasure']),
                  yValue: val.pointValue,
                  // Never display 'string's value unit of Measure - CRAB-15373
                  sourceValueUnitOfMeasure:
                    val.sourceValueUnitOfMeasure !== STRING_UOM ? val.sourceValueUnitOfMeasure : '',
                  valueUnitOfMeasure: val.valueUnitOfMeasure !== STRING_UOM ? val.valueUnitOfMeasure : '',
                } as CursorPoint),
            ),
          )
          .value();

        addCursor(cursor.xValue, points, isCapsuleTime);
      })
      .value();

    this.drawNowCursor(chart, trendData, durationData, longestCapsuleSeriesDuration);
  }

  getPointValue(yValue: PointerYValue) {
    return _.isNumber(yValue.pointValue) ? yValue.pointValue : yValue.closestPoint[1];
  }

  /**
   * Given an array of yValues for all series on the chart, it returns an approximate number of yValues
   * that will fit into each lane
   * One yValue is selected for yValues in close proximity
   * The values are evenly spread.
   *
   * @param yValues - The yValues to be reduced
   * @param items - list of items in the display
   * @param chartHeight - height of the chart
   *
   * @returns the reduced set of yValues
   */
  reduceYValues(yValues: PointerYValue[], items: any[], chartHeight: number): PointerYValue[] {
    const itemRanges = getItemRanges(items);
    const allYValuesBySignalId = _.chain(yValues)
      .reject((yValue) => yValue.closestPoint[0] === null)
      .uniqBy('pointValue')
      .reduce((result, value) => {
        const signalId = value.interestId || value.id;
        (result[signalId] || (result[signalId] = [])).push(value);
        return result;
      }, {})
      .value();
    const reducedValues = [];
    const maxPointsPerSeries = Math.floor(
      chartHeight / chartLanes.getNumberOfDisplayedLanes(items) / CURSOR_TOOLTIP_HEIGHT,
    );

    const setReducedValues = (sortedYValues: PointerYValue[], proximityThreshold: number, indexIncrement: number) => {
      let basePoint = this.getPointValue(sortedYValues[0]);
      reducedValues.push(sortedYValues[0]);
      for (let i = 1; i < maxPointsPerSeries; i++) {
        const yValue = sortedYValues[Math.floor(i * indexIncrement)];
        if (!yValue) {
          break;
        }
        const point = this.getPointValue(yValue);
        if (Math.abs(point - basePoint) > proximityThreshold) {
          reducedValues.push(yValue);
          basePoint = point;
        }
      }
    };

    _.forEach(allYValuesBySignalId, (yValues, id) => {
      const sortedYValues = _.orderBy(yValues, (yValue: any) => yValue.closestPoint[1], 'desc');
      const [max, min] = itemRanges[id];
      const range = max - min;
      const proximityThreshold = range / Math.max(maxPointsPerSeries, 20);

      const indexIncrement = Math.max(1, (sortedYValues.length - 1) / (maxPointsPerSeries - 1));
      setReducedValues(sortedYValues, proximityThreshold, indexIncrement);
    });

    return reducedValues;
  }

  /**
   * Returns an array of point values for the specified x position. Values are interpolated as appropriate using the
   * appropriate interpolation method (step, linear).
   *
   * @param chart - Highcharts chart on which to draw cursors
   * @param xValue - xValue location of pointer
   * @param {Object[]} items - List of items currently in the chart
   * @returns Array of objects, one for each cursor to display. Each object contains the ID of the item,
   *   pointer value, whether to show the indicator, and the x/y values of the closestPoint on the item.
   */
  calculatePointerValues(
    chart: Highcharts.Chart,
    xValue: number,
    items: any[],
    trendData: TrendDataForCursors,
    xAxisOverride: Highcharts.Axis = undefined,
  ): PointerYValue[] {
    const cursorItems = _.filter(items, (item) =>
      _.includes([ITEM_TYPES.SERIES, ITEM_TYPES.SCALAR, ITEM_TYPES.CAPSULE], item.itemType),
    );
    const selected = _.some(cursorItems, 'selected');

    // Retrieve or interpolate the corresponding y-values as needed
    return _.chain(cursorItems)
      .filter((item) => !selected || (selected && item.selected))
      .flatMap((series) => {
        const data = _.result(_.find(chart.series, { userOptions: { id: series.id } }), 'data', []);
        if (!series.shadedAreaUpper || !series.shadedAreaLower) {
          return this.calculatePointerValue(
            chart,
            series,
            data,
            xValue,
            (point) => _.get(point, 'x'),
            (point) => _.get(point, 'y'),
            trendData,
            xAxisOverride,
          );
        } else {
          const values = [];
          if (_.includes([SHADED_AREA_CURSORS.BOTH, SHADED_AREA_CURSORS.LOWER], series.shadedAreaCursors)) {
            values.push(
              this.calculatePointerValue(
                chart,
                series,
                data,
                xValue,
                (point) => _.get(point, 'x'),
                (point) => _.get(point, 'low'),
                trendData,
                xAxisOverride,
              ),
            );
          }

          if (_.includes([SHADED_AREA_CURSORS.BOTH, SHADED_AREA_CURSORS.UPPER], series.shadedAreaCursors)) {
            values.push(
              this.calculatePointerValue(
                chart,
                series,
                data,
                xValue,
                (point) => _.get(point, 'x'),
                (point) => _.get(point, 'high'),
                trendData,
                xAxisOverride,
              ),
            );
          }
          return values;
        }
      })
      .value();
  }

  /**
   * Helper method for calculating a pointer value for a single series. Some series like shadedArea series have
   * multiple points produce by a single series so this function is called multiple times to get the upper and lower
   * values for the series.
   */
  calculatePointerValue(
    chart: Highcharts.Chart,
    series,
    seriesDataArray,
    xValue: number,
    getX,
    getY,
    trendData: TrendDataForCursors,
    xAxisOverride: Highcharts.Axis = undefined,
  ): PointerYValue {
    const result = getYValue(seriesDataArray, xValue, getX, getY, series.interpolationMethod);
    let pointValue: number | string = '';
    if (
      trendData.view === TREND_VIEWS.CAPSULE &&
      !trendData.dimDataOutsideCapsules &&
      _.isFinite(result.closestPoint[0]) &&
      (result.closestPoint[0] < 0 || result.closestPoint[0] > series.duration)
    ) {
      // If capsule time and not dimming, then don't show a value outside of a capsule since the samples are not visible
      result.yValue = null;
      result.closestPoint = [null, null];
    }
    // without this the first zero-length capsule will be missing the hover cursor label
    if (series.itemType === ITEM_TYPES.CAPSULE && !result.closestPoint[0]) {
      if (xValue < getX(seriesDataArray[0])) {
        result.closestPoint = [getX(seriesDataArray[0]), getY(seriesDataArray[0])];
      }
    }
    const closeEnough = xValueCloseEnough(chart, result.closestPoint[0], xValue, series, xAxisOverride);
    const partialPointerValue = {
      sampleDisplayOption: series.sampleDisplayOption,
      color: series.color,
      formatOptions: series.formatOptions,
      isStringSeries: series.isStringSeries,
      stringEnum: series.stringEnum,
      itemType: series.itemType,
      lane: _.get(series, 'lane', 1),
      capsuleSetId: series.capsuleSetId,
      itemId: series.id,
    };

    if (series.itemType === ITEM_TYPES.CAPSULE) {
      const zeroLengthCapsule = series.sampleDisplayOption === SAMPLE_OPTIONS.SAMPLES;
      return {
        ...partialPointerValue,
        pointValue: result.closestPoint[1],
        id: series.id,
        closestPoint: result.closestPoint,
        showIndicator: closeEnough && !series.uncertainZeroLengthOverlay,
        labelText: _.chain(seriesDataArray)
          .chunk(3)
          // Find the capsule that overlaps the xValue
          .find((group: any[]) => {
            if (zeroLengthCapsule) {
              const chartX = chart.xAxis[0].toPixels(xValue, true);
              const pointX = group[0].plotX;
              return chartX >= pointX - ZERO_CAPSULE_TOLERANCE && chartX <= pointX + ZERO_CAPSULE_TOLERANCE;
            }
            return xValue >= group[0].x && xValue <= group[1].x;
          })
          .head()
          .get('labelText', '')
          .value(),
      };
    } else if (!series.isStringSeries) {
      // For Bar Charts and Samples only we don't want to get interpolated values as we move across the bar's
      // width, so we use the actual value
      if (
        _.includes(
          [SAMPLE_OPTIONS.BAR, SAMPLE_OPTIONS.SAMPLES],
          _.get(series, 'sampleDisplayOption', SAMPLE_OPTIONS.LINE),
        ) &&
        closeEnough
      ) {
        pointValue = _.isFinite(result.closestPoint[1]) ? result.closestPoint[1] : '';
      } else if (_.isFinite(result.yValue) && seriesDataArray.length > 1) {
        // If only one point in the series array, then only display a value when the cursor is close enough
        pointValue = result.yValue;
      } else if (closeEnough) {
        /**
         * If we show a hover indicator point in the chart we also expect to see a y-value in the details pane
         * Interpolation doesn't work in that case as with Singleton points there are no neighbors to
         * interpolate with. So, in case there is no y-value but a closestPoint we check to see if that point
         * falls within the delta that displays a hover indicator, and if so, we pull the y-value from that
         * pane to display in the details pane.
         */
        pointValue = _.isFinite(result.closestPoint[1]) ? result.closestPoint[1] : '';
      }

      const { valueUnitOfMeasure, sourceValueUnitOfMeasure } = series;
      return {
        ...partialPointerValue,
        pointValue,
        id: series.id,
        interestId: series.interestId,
        closestPoint: result.closestPoint,
        showIndicator: closeEnough,
        sourceValueUnitOfMeasure,
        valueUnitOfMeasure,
      };
    } else {
      if (series.stringEnum) {
        pointValue = _.result(_.find(series.stringEnum, ['key', result.yValue]), 'stringValue');
      } else {
        pointValue = result.yValue;
      }

      return {
        ...partialPointerValue,
        pointValue,
        id: series.id,
        closestPoint: result.closestPoint,
        showIndicator: closeEnough,
      };
    }
  }

  /**
   * Removes the vertical crosshair from the chart
   */
  clearHoverCursor() {
    deleteCursor(this.#hoverCursor);
    this.#hoverCursor = { ...DEFAULT_CURSOR_VALUES };
  }
}

/**
 * Mutates the element by setting it to undefined
 * This handles a bug in highcharts where the destroy function fails b/c the SVGElement was not fully initialized
 * and does not have a renderer
 */
function destroySVGElement(element: Highcharts.SVGElement) {
  element = _.attempt(() => {
    element?.destroy();
    return undefined;
  });
}

/**
 * Format the x-label from the xValue of the cursor. xValue is formatted as either a date/time or a duration
 * as appropriate.
 *
 * @param xValue - xValue of the cursor
 * @return Formatted date/time or duration
 */
export function formatXLabel(xValue: number, timezoneName: string): string {
  // Use a "magic number" here to determine if the number should be formatted as an absolute time or relative.
  // The magic number is the value of Jan 1, 1971 - 1 year from the epoch.
  const breakpoint = 31536000000;

  if (xValue > breakpoint) {
    return `${moment(xValue).tz(timezoneName).format('l')} ${moment(xValue).tz(timezoneName).format('LTS')}`;
  } else {
    return formatDuration(xValue);
  }
}

/**
 * Destroy the cursor specified from the chart
 *
 * @param  {cursor} cursor - Cursor to delete
 */
function deleteCursor(cursor: Cursor) {
  destroySVGElement(cursor.crosshair);
  destroySVGElement(cursor.anchor);
  destroySVGElement(cursor.xLabel);
  destroySVGElement(cursor.deleteIcon);
  _.forEach(cursor.points, (points) => _.forEach(points, deleteCursorPoint));
}

/**
 * Destroy a single cursor point from the chart
 *
 * @param {Object} point - Point to be destroyed
 * @param {SVGElement} point.circle - SVG circle for this point
 * @param {SVGElement} point.label - SVG label for this point
 */
function deleteCursorPoint(point: CursorPoint) {
  destroySVGElement(point.circle);
  destroySVGElement(point.label);
}

/**
 * Determines whether two x-values are close enough to be shown as the value under a cursor.
 *
 * @param {Highchart} chart - Highcharts chart on which to draw cursors
 * @param {Number} xPoint1 - First x-value
 * @param {Number} xPoint2 - Second x-value
 * @return {Boolean} Returns true if the two values are close enough; otherwise, false
 */
function xValueCloseEnough(
  chart: Highcharts.Chart,
  xPoint1: number,
  xPoint2: number,
  series,
  xAxisOverride: Highcharts.Axis = undefined,
) {
  if (series.itemType === ITEM_TYPES.SCALAR) {
    return true;
  }

  const xAxis = xAxisOverride ?? chart.xAxis[0];
  const delta = Math.abs(xAxis.translate(xPoint1) - xAxis.translate(xPoint2));

  if (series.sampleDisplayOption === SAMPLE_OPTIONS.SAMPLES) {
    return delta < ZERO_CAPSULE_TOLERANCE;
  } else if (series.sampleDisplayOption === SAMPLE_OPTIONS.BAR) {
    return delta < series.lineWidth / 2;
  } else {
    return delta < POINT_MARKER_SIZE / 2;
  }
}

function cursorSVGPrefix(cursor: Cursor) {
  return cursor.name && !cursor.selected ? 'cursor' : 'hover';
}
