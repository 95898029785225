// @ts-strict-ignore
import _ from 'lodash';
import { sqAuthApi } from '@/sdk/api/AuthApi';
import { AUTH_CHANGE_BROADCAST_CHANNEL, emitBroadcastChannelMessage } from '@/services/broadcastChannel.utilities';
import { DatasourceOutputV1 } from '@/sdk/model/DatasourceOutputV1';
import { AuthInputV1 } from '@/sdk/model/AuthInputV1';
import { logError } from '@/utilities/logger';
import { close as closeSocket } from '@/utilities/socket.utilities';
import { base64guid } from '@/utilities/utilities';
import { removeCsrfToken } from '@/utilities/auth.utilities';
import { notifyError } from '@/utilities/screenshot.utilities';
import { authAutoLogin } from '@/services/systemConfiguration.utilities';
import { generateNewSessionId, setCurrentUser } from '@/workbench/workbench.actions';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';
import { isAxiosError } from '@/requests/axios.utilities';
import HttpCodes from 'http-status-codes';
import {
  getLoginLink,
  getNoWorksheetLink,
  getWorkbooksLink,
  goTo,
  navigateToErrorPage,
  navigateToForbiddenPage,
  navigateToUnauthorizedPage,
} from '@/main/routing.utilities';
import { ROUTE_NO_WORKBOOK, ROUTE_NO_WORKSHEET } from '@/main/app.constants';
import { errorToast } from '@/utilities/toast.utilities';

/**
 * Logs in to the Seeq application.
 *
 * @param  {AuthInputV1} authCredentials - The authentication credentials
 * @param  {String} authProviderClass - The authProviderClass property of the AuthProvider
 * @param  {String} authProviderId - The authProviderId property of the AuthProvider
 * @return {Promise} A promise that resolves if login was successful
 */
export function login(authCredentials: AuthInputV1, authProviderClass: string, authProviderId: string) {
  if (headlessRenderMode()) {
    notifyError('Authentication Issue: Prevented login request');
    logError('Screenshot mode should not make requests to login');
    return Promise.resolve();
  }

  generateNewSessionId();

  // See the note in AuthQueriesV1 for why it needs to be in the URL as well as the body
  const params =
    authProviderClass && authProviderId ? { authProviderClass, authProviderId, sequenceId: base64guid() } : {};

  return sqAuthApi.login({ ...authCredentials, authProviderClass, authProviderId }, { params }).then((response) => {
    emitBroadcastChannelMessage(AUTH_CHANGE_BROADCAST_CHANNEL);
    return setCurrentUser();
  });
}

/**
 * Fetches a list of all available Authentication Providers.
 *
 * @returns {Promise} that resolves with a list of available Authentication Providers
 */
export function fetchAuthenticationProviders(): Promise<DatasourceOutputV1[]> {
  return sqAuthApi.getAuthProviders().then(({ data }) => data.authProviders);
}

/**
 }

 /**
 * Logs out and redirect to the login page. It is important that a full redirect be done to ensure that all
 * in-memory state in services and stores is removed.
 *
 * @param - if true prevent immediate auto-login
 */
export function logout(returnToLink?: string, userInitiated = true) {
  if (headlessRenderMode()) {
    notifyError('Authentication Issue: Prevented invalidation of provided auth token');
    logError('Screenshot mode should not make requests to logout');
    return Promise.resolve();
  }

  return sqAuthApi.logout().finally(() => {
    removeCsrfToken();
    closeSocket();
    emitBroadcastChannelMessage(AUTH_CHANGE_BROADCAST_CHANNEL);

    const extraSearchParams = new URLSearchParams();
    // Prevent immediate auto re-login if the user logs out on a system that is configured to automatically login
    if (authAutoLogin() && userInitiated) {
      extraSearchParams.append('autoLogin', 'false');
    }
    goTo(getLoginLink(returnToLink, extraSearchParams));
  });
}

/**
 * Helper function that checks if a request returned a NOT_FOUND, BAD_REQUEST, or FORBIDDEN and, if so, returns the
 * the specified route constant which allows the stateChangeError handler to respond correctly. BAD_REQUEST is
 * checked because the backend will return that status code if the guid in the URL is missing or malformed.
 *
 * @param routeConstantNotFound - Constant to return if response status is NOT_FOUND or BAD_REQUEST
 * @param routeConstantForbidden - Constant to return if response status is FORBIDDEN
 * @param error - The error response
 * @returns Rejects with either the routeConstant or the original error
 */
export function checkForNotFoundOrForbidden(
  routeConstantNotFound: string,
  routeConstantForbidden: string,
  error: unknown,
  isLoadingWorksheet = false,
): unknown {
  const isForbidden = isAxiosError(error) && error.response.status === HttpCodes.FORBIDDEN;
  const isNotFoundOrBadRequest =
    isAxiosError(error) && _.includes([HttpCodes.NOT_FOUND, HttpCodes.BAD_REQUEST], error.response.status);

  if (isForbidden) {
    isLoadingWorksheet ? navigateToUnauthorizedPage() : navigateToForbiddenPage();
    return error;
  }

  if (isNotFoundOrBadRequest) {
    if (routeConstantNotFound === ROUTE_NO_WORKBOOK) {
      goTo(getWorkbooksLink());
    }
    // This handles the case, where a link to a particular worksheet has been saved/bookmarked but that
    // worksheet has been deleted (deleted as in DELETE in api, archived worksheets load).
    // This isn't the greatest behavior because the action the user should take isn't very clear and there is no
    // button to take the user back to the workbench. However, it is a little bit better than having a link
    // that doesn't work and redirects to the homescreen.
    if (routeConstantNotFound === ROUTE_NO_WORKSHEET) {
      goTo(getNoWorksheetLink());
    }
  }

  navigateToErrorPage();
  errorToast({ httpResponseOrError: error });

  return error;
}
