import React, { useRef } from 'react';
import _ from 'lodash';
import { ChartDrawService, ChartServiceProps } from '@/services/chartDraw.service';
import { useResizeWatcher } from '@/core/hooks/useResizeWatcher.hook';
import { AutoUpdateMode } from '@/trendData/duration.store';
import { notifyIfChanged } from '@/core/utilities';
import { LabelDisplayConfiguration } from '@/utilities/label.utilities';
import { CapsuleTimeColorMode, PropertyColumn, TREND_VIEWS } from '@/trendData/trendData.constants';
import { CapsuleTimeLegend } from '@/trend/CapsuleTimeLegend.organism';
import { SerializedRange } from '@/datetime/dateTime.utilities';
import { cleanseTrendProps, provideVisualizationData } from '@/annotation/ckEditorPlugins/components/content.utilities';
import { Visualization } from '@/annotation/ckEditorPlugins/components/content.utilities.constants';
import { headlessRenderMode } from '@/services/headlessCapture.utilities';
import {
  ConditionValuesForLabels,
  CursorData,
  DurationDataForCursors,
  TrendDataForCursors,
  TrendValuesForLabels,
} from '@/annotation/interactiveContent.types';

export interface ChartViewActions {
  clearPointerValues: () => void;
  findCapsuleItem: (id: string) => void; // sqTrendCapsuleStore.findItem
  findChartCapsule: (id: string, type: number) => Object; // sqTrendCapsuleStore.findChartCapsule
  loadToolForEdit: (id: string, editMode?: string) => void; // sqInvestigateActions.loadToolForEdit
  pickSelectedRegion: () => void;
  removeSelectedRegion: () => void;
  selectItems: (item: Object, items: Object[], e: Object) => void;
  setCapsuleTimeOffsets: (lower: number, upper: number, option?: string) => void; // sqTrendActions.setCapsuleTimeOffsets
  setPointerValues: (xValue: number, yValues: any[]) => void;
  setSelectedRegion: (min: number, max: number, option?: string) => void;
  setYExtremes: (extremes: { min: number; max: number; axisAlign: string }[]) => void;
  showAnnotationEntry: (id: string, exposeJournalTab?: boolean) => void; // sqAnnotationActions.showEntry
  updateDisplayRangeTimes: (start: number, end: number, option?: string) => void; // sqDurationActions.displayRange.updateTimes
  afterChartUpdate?: () => void;
  onContentUpdate?: () => void;
}

export interface ChartViewConfiguration {
  autoUpdateMode: AutoUpdateMode; // sqDurationStore.autoUpdate.mode
  capsuleTimeColorMode: CapsuleTimeColorMode; // sqTrendStore.capsuleTimeColorMode
  capsuleTimeOffsets: { lower: number; upper: number };
  isCapsuleTime: boolean;
  isDimmed: boolean;
  isPickingMode: boolean;
  labelDisplayConfiguration: LabelDisplayConfiguration; // sqTrendStore.labelDisplayConfiguration
  longestCapsuleSeriesDuration: number; // sqTrendSeriesStore.longestCapsuleSeriesDuration
  showCapsuleLaneLabels: boolean; // sqTrendStore.showCapsuleLaneLabels
  showGridlines: boolean; // sqTrendStore.showGridlines
  customizationMode: boolean; // sqTrendStore.customizationMode
  trendEnd: number;
  trendStart: number;
  view: TREND_VIEWS;
  isInTopic: boolean;
}

export interface ChartViewData {
  annotatedItemIds: any; // sqAnnotationStore.annotatedItemIds
  breaks: any[];
  capsuleEditingId: string; // sqTrendCapsuleStore.editingId
  items: any[];
  lanes: number[]; // sqTrendStore.lanes
  selectedRegion: { min: number; max: number };
  selectedTimezone: {
    name: string;
    displayName: string;
    offset: string;
    offsetMinutes: number;
  };
  cursorChange: number;
  summaryLabel: string;
  serializedDateRange: SerializedRange;
  sortedColumn: PropertyColumn;
  capsules: any[]; // sqTrendCapsuleStore.items
  sqTrendStoreData: TrendDataForCursors & TrendValuesForLabels;
  sqDurationStoreData: DurationDataForCursors;
  sqCursorStoreData: CursorData;
  sqTrendCapsuleSetStoreData: ConditionValuesForLabels;
}

export interface ChartInfoProps extends ChartViewData, ChartViewConfiguration {}

interface ChartViewIF {
  actions: ChartViewActions;
  configuration: ChartViewConfiguration;
  data: ChartViewData;
}

export const ChartView: React.FunctionComponent<ChartViewIF> = (props) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const serviceRef = useRef<ReturnType<typeof ChartDrawService>>();
  const oldProps = useRef<typeof props>();
  const callbacksMap = useRef([
    {
      property: 'data.items',
      callback: () => serviceRef.current?.updateItems(),
    },
    {
      property: 'configuration.view',
      callback: () => serviceRef.current?.destroyChart(),
    },
    {
      property: 'data.annotatedItemIds',
      callback: () => serviceRef.current?.updateAnnotatedItemsIds(),
    },
    {
      property: 'data.selectedRegion',
      callback: () => serviceRef.current?.updateSelectedRegion(),
    },
    {
      property: 'configuration.isPickingMode',
      callback: () => serviceRef.current?.updateIsPickingMode(),
    },
    {
      property: 'data.selectedTimezone',
      callback: () => serviceRef.current?.updateSelectedTimezone(),
    },
    {
      property: 'data.breaks',
      callback: () => serviceRef.current?.updateBreaks(),
    },
    {
      property: 'configuration.isDimmed',
      callback: () => serviceRef.current?.updateIsDimmed(),
    },
    {
      property: 'data.cursorChange',
      callback: () => serviceRef.current?.syncCursors(),
    },
    {
      property: 'configuration.labelDisplayConfiguration',
      callback: () => serviceRef.current?.syncTrendStore('labelDisplayConfiguration'),
    },
    {
      property: 'configuration.showGridlines',
      callback: () => serviceRef.current?.syncTrendStore('showGridlines'),
    },
    {
      property: 'configuration.showCapsuleLaneLabels',
      callback: () => serviceRef.current?.syncTrendStore('showCapsuleLaneLabels'),
    },
    {
      property: 'configuration.customizationMode',
      callback: () => serviceRef.current?.syncTrendStore('customizationMode'),
    },
    // defined groups - if ANY of these changed, call the function
    {
      property: ['configuration.trendStart', 'configuration.trendEnd', 'configuration.capsuleTimeOffsets'],
      callback: () => serviceRef.current?.updateXExtremes(),
    },
    {
      property: ['configuration.autoUpdateMode', 'configuration.capsuleTimeColorMode', 'data.capsuleEditingId'],
      callback: () => serviceRef.current?.syncAutoUpdate(),
    },
  ]);

  const infoProps: ChartInfoProps = { ...props.data, ...props.configuration };

  if (chartRef.current) {
    if (_.isUndefined(serviceRef.current)) {
      serviceRef.current = ChartDrawService({ ...infoProps, ...props.actions } as ChartServiceProps, chartRef.current);
      serviceRef.current.updateItems();
      if (props.configuration.isInTopic) {
        serviceRef.current.syncCursors();
      }
    }
  }

  // rerender
  if (!_.isUndefined(oldProps.current)) {
    serviceRef.current?.updateScope(infoProps);
    // check diffs
    notifyIfChanged(oldProps.current, props, callbacksMap.current);
  }
  if (!_.isUndefined(serviceRef.current)) {
    oldProps.current = props;
  }
  useResizeWatcher({
    elementRef: chartRef,
    callOnLoad: false,
    callback: () => serviceRef.current?.reflowTrend(),
  });

  if (headlessRenderMode()) {
    provideVisualizationData({
      ...cleanseTrendProps(props),
      visualization: Visualization.TREND,
    });
  }

  return (
    <div className="flexFill flexRowContainer">
      <div className="chart chartView flexFill mr10" ref={chartRef} id="chartDisplay" data-testid="chartDisplay" />
      {props.data.sqTrendStoreData.view === TREND_VIEWS.CAPSULE && (
        <CapsuleTimeLegend
          conditions={props.data.sqTrendCapsuleSetStoreData.items}
          capsules={props.data.capsules}
          sortedColumn={props.data.sortedColumn}
          view={props.data.sqTrendStoreData.view}
          capsuleTimeColorMode={props.configuration.capsuleTimeColorMode}
        />
      )}
    </div>
  );
};
